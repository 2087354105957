<template>
  <Expand type="login" transition="slide-left" :class="{ 'is-success' : isSuccess }">
    <template v-slot:top>
      <h2 v-if="!isSuccess">Log In</h2>
    </template>
    <template v-slot:content>
      <template v-if="isSuccess">
        <h3 class="h4">You've successfully been logged in!</h3>
        <div class="form">
          <div class="form__row form__row--button">
            <div class="form__field">
              <Button text="Close" @click="closeHeaderMenu" :isAltBG="true" :hasClose="true" />
            </div>
            <div class="form__field">
              <ArrowLink to="/account" text="Go To My Account" />
              <small>Closing in {{ successCountdownNum }}</small>
            </div>
          </div>
        </div>
      </template>
      <form v-else class="form" :class="{ 'has-errors' : $v.$anyError }" @submit.prevent="handleSubmit">
        <div class="form__row">
          <div class="form__field form__field--large" :class="{ 'has-error': $v.email.$error }">
            <label class="accessible" for="email">Email (Required)</label>
            <input id="email" name="email" type="text" placeholder="Email" v-model.trim="email" />
            <span class="error" v-if="$v.email.$error && !$v.email.required">This field is required</span>
            <span class="error" v-if="$v.email.$error && !$v.email.email">Please enter a valid email address</span>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field form__field--large" :class="{ 'has-error': $v.password.$error }">
            <label class="accessible" for="password">Password (Required)</label>
            <input id="password" name="password" :type="showPassword ? 'text' : 'password'" placeholder="Password" v-model.trim="password" />
            <span class="error" v-if="$v.password.$error && !$v.password.required">This field is required</span>
            <span class="error" v-if="$v.password.$error && !$v.password.minLength">Your password must be a minimum of {{ $v.password.$params.minLength.min }} characters long</span>
            <div role="button" tabindex="0" @click="openHeaderMenuExpand('forgot-password')" class="link">Forgot your password?</div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field">
            <input id="show_password" type="checkbox" name="show_password" v-model="showPassword" value="true"><label for="show_password">Show Password?</label>
          </div>
        </div>
        <div class="form__row form__row--button">
          <div class="form__field">
            <Button type="submit" :text="buttonText" />
          </div>
          <div class="form__field">
            <span>
              Don't have an account? <ArrowLink @click="openHeaderMenuExpand('register')" text="Register" :isFakeButton="true" />
            </span>
          </div>
        </div>
        <FormFeedback :showFeedback="showFeedback" :submitState="submitState" :submitMessage="submitMessage" />
      </form>
    </template>
  </Expand>
</template>

<script>
  import { mapActions } from 'vuex';
  import { required, email, minLength } from 'vuelidate/lib/validators';

  import formMixin from '@/mixins/formMixin';
  import loginMixin from '@/mixins/loginMixin';

  import Expand from '@/components/header/Expand';
  import Button from '@/components/global/Button';
  import ArrowLink from '@/components/global/ArrowLink';
  import FormFeedback from '@/components/global/FormFeedback';

  export default {
    name: 'Login',

    mixins: [ formMixin, loginMixin ],

    components: {
      Expand,
      Button,
      ArrowLink,
      FormFeedback
    },

    data() {
      return {
        email: null,
        password: null,
        showPassword: false,
        defaultButtonText: 'Log In',
        successCountdownNum: 5
      }
    },

    computed: {
      isSuccess() {
        return this.submitState == 'success';
      }
    },

    validations: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(7)
      },
    },

    watch: {
      submitState(newVal) {
        if(newVal == 'success') {
          setInterval(() => {
            this.successCountdownNum -= 1;

            if(this.successCountdownNum == 0) {
              this.closeHeaderMenu();
            }
          }, 1000);
        }
      }
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand',
        'closeHeaderMenu'
      ]),
      async handleSubmit() {
        this.$v.$touch();
        this.showFeedback = false;
        this.isSubmitting = true;

        if (this.$v.$invalid) {
          this.handleFormFeedback('error');
        } else {
          this.handleFormFeedback('success', 'You have successfully logged in!');

          this.logIn();
        }
      }
    }
  }
</script>
