import { createCustomerAccessToken } from '@/shopify/customer'

export default {
  methods: {
    async getCustomerAccessToken() {
      const { data } = await this.$shopify.client.mutate({
        mutation: createCustomerAccessToken,
        variables: {
          input: {
            email: this.email,
            password: this.password,
          },
        },
      })

      const { customerAccessTokenCreate } = data

      if (customerAccessTokenCreate.customerUserErrors.length) {
        const { message } = customerAccessTokenCreate.customerUserErrors[0]

        var errorMsg = ''
        if(message == 'Unidentified customer')
          errorMsg = 'Incorrect Password'
        else
          errorMsg = message;

        /*
        const errorMsg =
          message === 'Unidentified customer'
            ? "We couldn't find an account with that email"
            : message
         */

        this.handleFormFeedback('error', errorMsg)
        throw new Error(message)
      }

      const { accessToken, expiresAt } =
        customerAccessTokenCreate.customerAccessToken

      return {
        accessToken,
        expiresAt,
      }
    },

    async logIn() {
      const token = await this.getCustomerAccessToken()

      setTimeout(async () => {
        this.$store.dispatch('setAccessToken', token)
        await this.$store.dispatch('setUserData')
        this.$store.dispatch('closeHeaderMenu')

        this.$liveChat.handleLogin()

        this.$klaviyo.identifyCustomer(this.email)
      }, 2500)
    },
  },
}
